import {Component, OnInit, OnDestroy} from '@angular/core';
import {NotificationUpdateRequest} from '../../../pages/notification/notification.model';
import {HttpParams} from '@angular/common/http';
import {API_URLS} from '../../../constants/api-urls';
import {AlertService} from '../../../services/shared-services/alert.service';
import {ApiUrlService} from '../../../services/shared-services/api-url.service';
import {ApiService} from '../../../services/shared-services/api.service';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {LayoutService} from '../../../@core/utils';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {UserData} from '../../../@core/data/users';
import {JwtUtilService} from '../../../services/shared-services/jwt-util.service';
import {Router} from '@angular/router';
import { NoticeService } from '../../../services/module-based-services/notice.service';
import { LocalDataSource } from 'ng2-smart-table';
import {EmployeeService} from '../../../services/module-based-services/employee.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
      <nb-layout windowMode>
          <nb-layout-header subheader class="mobile-header">
              <ngx-header class="mobile-header"></ngx-header>
          </nb-layout-header>

          <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>

              <ngx-logo></ngx-logo>
              <!--                            <ngx-header></ngx-header>-->
              <ng-content select="nb-menu"></ng-content>

          </nb-sidebar>

          <nb-layout-column class="column-layout">
              <nb-card>
                <div class="row" style="margin: 10px 10px 10px 10px">
                  <div class="col-md-12">

                  <div class="d-flex align-items-center justify-content-between">
                    <div *ngIf="headlineText" class="notice">
                        Notice<div *ngIf="headlineText" class="notice-mob">
                      </div>

                      </div>

                    <!-- Custom Marquee for Headline -->
                    <div class="marquee">
                      <span *ngIf="headlineText" >
                      {{ headlineText }}
                      </span>
                    </div>

                    <!-- Header Container -->
                    <div class="header-container">
<!--                      <div class="notification-icon" (click)="toggleDropdown()">-->
<!--                        <i class="fa fa-bell" aria-hidden="true">-->
<!--                          <span class="badge" *ngIf="count > 0">{{ count }}</span>-->
<!--                        </i>-->
<!--                      </div>-->
                      <nb-actions size="small">
                        <nb-action class="user-action" *nbIsGranted="['view', 'user']">
                          <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly"
                                  [name]="userName"
                                  [picture]="picture">
                          </nb-user>
                        </nb-action>
                      </nb-actions>
                    </div>

                  </div>


                    <div class="notification-dropdown" [class.show]="dropdownVisible">
                      <div class="dropdown-header"></div>
                      <div class="dropdown-item-container">
                        <div class="dropdown-item" [style.background-color]="colour(notification.seen)"
                             *ngFor="let notification of notificationList"
                             (click)="getNotification(notification.id)">
                          <li>{{ notification.subject }}<p
                            style="font-size: 7px; float: right">{{notification.timeBetween}}</p></li>
                        </div>
                      </div>
                      <div class="dropdown-footer-only" *ngIf="5 >= size"></div>
                      <div class="dropdown-footer" *ngIf="5 < size">
                        <div class="dropdown-item see-more " (click)="showMore()">
                          See More
                        </div>
                        <div class="dropdown-item close-button " (click)="closeDropdown()">
                          Close
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </nb-card>
              <!-- Marquee div to be displayed only on mobile -->
              <div class="d-flex align-items-center justify-content-between">
                <div *ngIf="headlineText" class="notice-mob">
                      Notice
                      </div>
                <div class="marquee-mobile">
                  <span *ngIf="headlineText">{{ headlineText }}</span>
                </div>
              </div>
              <div class="custom-modal" *ngIf="isModalOpen">
                  <div class="custom-modal-content">
                      <span class="close-modal" (click)="closeModal()">&times;</span>
                      <h6>Notification</h6>
                      <p>{{notification?.subject}}.</p>
                      <h6>Details</h6>
                      <p>{{notification?.massage}} by {{notification?.updateByName}}.</p>
                  </div>
              </div>
              <ng-content select="router-outlet"></ng-content>
          </nb-layout-column>

          <nb-layout-footer>
              <ngx-footer></ngx-footer>
          </nb-layout-footer>
      </nb-layout>

  `,
})
export class OneColumnLayoutComponent implements OnInit, OnDestroy {
  isModalOpen = false;
  serviceUrl: string;
  userRole = null;
  userId = null;
  notificationList = [];
  notification: any;
  page: number = 0;
  size: number = 10;
  count: number;
  dropdownVisible = false;
  notificationVisible = false;
  notificationAll = false;
  updateRequest: NotificationUpdateRequest = new NotificationUpdateRequest();
  noticeList: any = new LocalDataSource();


  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  userName: string;
  picture: SafeUrl = 'assets/images/avatar.png';

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  // userMenu = [{ title: 'Profile' }, { title: 'Reset Password' }, { title: 'Log out', link: '/auth/logout', }];
  userMenu = [{title: 'Profile', link: '/pages/profile'},
    {title: 'Reset Password', link: '/reset-password'},
    {title: 'Log out', link: '/logout'}];
  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private authService: AuthenticationService,
              private jwtUtilService: JwtUtilService,
              private apiService: ApiService,
              private apiUrlService: ApiUrlService,
              private notiService: NoticeService,
              private alertService: AlertService,
              private employeeService: EmployeeService,
              private sanitizer: DomSanitizer,
              private router: Router) {
    this.apiUrlService.getUrl().subscribe((x) => {
      this.serviceUrl = x.url;
    });
    this.userRole = authService.getRole();
    this.userId = authService.getUser();
  }
  ngOnInit(): void {
    this.getAllNotice();
    this.currentTheme = this.themeService.currentTheme;
    const tokenInfo = localStorage.getItem('auth_app_token');
    const userInfo = this.jwtUtilService.getDataFromJWT(JSON.parse(tokenInfo).value);
    this.user = this.authService.getUser();
    const stringArray = this.user?.name.split(' ');

    this.userName = this.authService.getUser()?.name;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    this.getNotificationList();
    this.getCount();
    this.getEmployeeProfilePicture(this.userId.id, this.user?.gender);

    // Add this new subscription to handle menu item clicks
    this.menuService.onItemClick()
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        // Check if it's mobile screen
        if (window.innerWidth <= 575) {
          this.sidebarService.collapse('menu-sidebar');
        }
      });
  }
  headlineText: string = '';

  getAllNotice() {
    this.notiService.getNotice().subscribe(
      (value) => {
        this.noticeList = value;
        this.headlineText = this.noticeList
          .map((notice) => notice.headline.trim())
          .join('\u00A0\u00A0 || \u00A0\u00A0'); // Fixed the string concatenation
      },
      (error) => {
        this.alertService.showWarningAlert('Error occurred while getting notice list'); // Changed to showWarningAlert for error
      }
    );
  }

  getNotificationList() {
    const params = new HttpParams()
      .set('size', this.size);
    this.apiService.get(this.serviceUrl + API_URLS.NOTIFICATION.GET_LIST, params).subscribe(
      (res) => {
        this.notificationList = res.content;
        this.size = res.totalElements;
      });
  }

  closeDropdown(): void {
    this.dropdownVisible = false;
  }

  getCount() {
    this.apiService.get(this.serviceUrl + API_URLS.NOTIFICATION.GET_COUNT).subscribe(
      (res) => {
        this.count = res.replyMessage;

      });
  }

  showMore() {
    localStorage.setItem('size', String(this.size));
    this.router.navigate(['/pages/notification']);
  }

  toggleDropdown(): void {
    this.dropdownVisible = !this.dropdownVisible;
    console.log(this.dropdownVisible);
  }

  colour(seen: boolean) {
    if (seen) {
      return '#ffffff';
    } else {
      return '#afafaf';
    }
  }
  getNotification(notificationId: number) {
    const params = new HttpParams()
      .set('notificationId', notificationId);
    this.apiService.get(this.serviceUrl + API_URLS.NOTIFICATION.GET_NOTIFICATION, params).subscribe(
      (res) => {
        this.notification = res.replyMessage;
        this.notificationVisible = !this.notificationVisible;
          this.updateRequest.notificationId = this.notification?.id;
          this.updateRequest.employeeId = this.notification?.createdBy;
          this.openModal();
        if (this.notification.seen === false) {
          this.apiService.put(this.serviceUrl +
            API_URLS.NOTIFICATION.UPDATE_NOTIFICATION, this.updateRequest).subscribe(
            () => {
            });
        }
      });
  }

  openModal(): void {
    this.isModalOpen = true;
    this.closeDropdown(); // Close the dropdown when the modal is opened
  }

  closeModal(): void {
    this.isModalOpen = false;
    this.getNotificationList();
    this.getCount();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  /*toggleSidebarMobScreen(): boolean {
    const isMobileScreen = window.innerWidth <= 575;
    if (isMobileScreen) {
      this.sidebarService.toggle(true, 'menu-sidebar');
      this.layoutService.changeLayoutSize();
    }
    return false;
  }*/

  getEmployeeProfilePicture(id: number, gender: string) {
    if (id) {
      this.employeeService.getProfilePictureForProfile(id, 'profile_picture').subscribe({
        next: (value) => {
          if (value.replyMessage.profilePicture) {
            this.picture = value.replyMessage.profilePicture;
          } else {
            if (gender?.toLowerCase() === 'male') {
              this.picture = 'assets/images/avatar.png';
            } else if (gender?.toLowerCase() === 'female') {
              this.picture = 'assets/images/avatar-female.png';
            } else {
              this.picture = 'assets/images/avatar.png';
            }
          }
        },
        error: (error) => {
          this.alertService.showErrorAlert('Error occurred while getting image');
        }
      });
    }
  }

}
