import {Injectable} from '@angular/core';
import {ApiService} from '../shared-services/api.service';
import {ApiUrlService} from '../shared-services/api-url.service';
import {AlertService} from '../shared-services/alert.service';
import {Router} from '@angular/router';
import {NbDateService} from '@nebular/theme';
import {API_URLS} from '../../constants/api-urls';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NoticeService {

  serviceUrl: string = '';

  constructor(
    private apiService: ApiService,
    private apiUrlService: ApiUrlService,
    private alertService: AlertService,
    private router: Router,
    private dateService: NbDateService<Date>,
  ) {
    this.apiUrlService.getUrl().subscribe((x) => {
      this.serviceUrl = x.url;
    });

  }


  saveNotice(value: any) {
    return this.apiService.post(this.serviceUrl + API_URLS.NOTICE.COMMON_URL, value);
  }

  updateNotice(value: any) {
    const url = `${this.serviceUrl}${API_URLS.NOTICE.COMMON_URL}/${value}`;
    return this.apiService.get(url);
  }

  getAllNotice() {
    return this.apiService.get(this.serviceUrl + API_URLS.NOTICE.COMMON_URL);
  }

  getAttachment(id: any) {
    const url = `${this.serviceUrl}${API_URLS.NOTICE.ATTACHMENTS}/${id}`;
    return this.apiService.get(url);
  }

  getNotice() {
    return this.apiService.get(this.serviceUrl + API_URLS.NOTICE.GET_NOTICE);
  }

  updateNoticeStatus(id: number, status: boolean): Observable<any> {
    const url = `${this.serviceUrl}${API_URLS.NOTICE.STATUS}/${id}?status=${status}`;
    return this.apiService.put(url, {});
  }

}
