export const API_URLS = {
  ATTENDANCE: {
    JOB_CARD: '/attendance/job-card',
    ABSENT_COUNT: '/absent-count-by-employee',
    IN_TIME_COUNT: '/attendance-in-time-count',
    ATTENDANCE_HOUR_COUNT: '/month-wise-attendance-hour-count',
    ATTENDANCE_UPLOAD: '/attendance/upload',
    COMMON_URL: '/attendance',
    DAILY_ATTENDANCE: '/daily-attendance',
    DAILY_ATTENDANCE_LIST: '/daly-attendance-info-list',
    UNADJUSTED_ATTENDANCE_LIST: '/unadjusted-attendance',
    DAILY_ATTENDANCE_INFO_REPORT: '/daly-attendance-info-report',
    ATTENDANCE_REQUEST_COUNT: '/count-request',
    WEEKLY_ATTENDANCE_LIST: '/week-wise-attendance-info-list',
    MONTHLY_ATTENDANCE_LIST: '/month-wise-attendance-info-list',
    ATTENDANCE_LIST: '/attendance-info-list',
    ATTENDANCE_DETAILS_REPORT: '/attendance-details-report',
    DAILY_PRESENT: '/daily-present',
    WEEK_WISE_DURATION: '/week-wise-duration',
    WEEK_WISE_EXPECTED_DURATION: '/week-wise-expected-duration',
    DAILY_LEAVE: '/leave-today',
    MONTHLY_ATTENDANCE: '/attendance/monthly-reports?',
    EMPLOYEE_NAME_WITH_ID: '/employee-names-with-id',
    UPDATE_ATTENDANCE_BY_TYPE: '/update-attendance-by-type',
    UPDATE_ATTENDANCE: '/attendance/update-attendance',
    DAILY_ATTENDANCE_REPORT: '/attendance/daily-report',
    ATTENDANCE_DALY_REPORT: '/attendance-daly-report',
    UNADJUSTED_ATTENDANCE_REPORT: '/unadjusted-attendance-report',
    ATTENDANCE_DALY_EXCEL_REPORT: '/daly-attendance-excel-report',
    ATTENDANCE_DALY_FOR_LEAD_REPORT: '/attendance-daly-report-lead',
    DAILY_PERCENTAGE_REPORT: '/attendance-percentage-report',
    WEEK_WISE_ATTENDANCE_REPORT: '/week-wise-attendance-report',
    MONTH_WISE_ATTENDANCE_REPORT: '/month-wise-attendance-report',
    ATTENDANCE_EXEL_REPORT: '/attendance-exel-report',
    MONTHLY_ATTENDANCE_REPORT_EMPLOYEE: '/monthly-attendance-report',
    WEEK_WISE_ATTENDANCE_REPORT_TEAM_LEAD: '/week-wise-attendance-report-lead',
    UPDATE_REQUEST: '/attendance/update-attendance/request',
    REQUEST_APPROVE: '/attendance/update-attendance/approve',
    REQUEST_LIST: '/attendance/update-attendance/get_all',
    ATTENDANCE_REQUEST_LIST: '/attendance/attendance-request/get_all',
    GET_BY_ID: '/attendance/get-by-id',
    BACKUP: '/attendance-back-up',
  },
  NOTIFICATION: {
    GET_LIST: '/notification/get-list',
    GET_NOTIFICATION: '/notification/get-notification',
    UPDATE_NOTIFICATION: '/notification/update-notification?',
    GET_COUNT: '/notification/get-count',
  },
  TEAMLEAD: {
    JOB_CARD: '/attendance/team-lead/job-card',
    COMMON_URL: '/attendance/team-lead',
    DAILY_ATTENDANCE: '/daily-attendance/team-lead',
    MONTHLY_ATTENDANCE: '/attendance/team-lead/monthly-reports?',
    EMPLOYEE_NAME_WITH_ID: 'employee-names-with-id/team-lead',
    DAILY_ATTENDANCE_REPORT: '/attendance/team-lead/daily-report',
  },


  LEAVE: {
    SUBMIT_LEAVE_APPLICATION: '/leave-application',
    LEAVE_AVAILABLE_SPEND: '/employee-leave-available-spend-count',
    SAVE_LEAVE_BALANCE: '/save-leave-balance-by-emp',
    LEAVE_APPLICATION_HISTORY: '/leave-application/leave-history',
    GET_LEAVE_TYPE: '/leave-type',
    IS_LEAVE_BALANCE: '/is-leave-balance-have',
    LEAVE_APPROVE: '/leave-application-approval',
    GET_BY_SUPERVISOR: '/leave-application/get-by-supervisor',
    GET_BY_EMPLOYEE: '/leave-application-request/get-by-employee',
    GET_LEAVE_BALANCE: '/leave-balance-by-emp-id',
    GET_LEAVE_APPLICATION_COUNT: '/leave-application/count',
  },

  EMPLOYEE: {
    BASIC_URL: '/employee',
    EMPLOYEE_INFO_CHANGE_REQUEST: '/employee-info-change-request',
    EMPLOYEE_INFO_CHANGE_REQUEST_COUNT: '/employee-info-change-request-count',
    BASIC_INFO: '/basic-info',
    DOWNLOAD_URL: '/file/download',
    GET_PROFILE_PICTURE: '/file/get-profile-picture',
    GET_ALL_SUB_SBU: '/sbu/sub-sbu',
    JOB_DESCRIPTION: '/employee/job-description',
    SAVE_EMPLOYEE_ADMIN: '/save-employee-for-admin',
    ADDRESS: '/address',
  },
  DEPARTMENT: {
    BASIC_URL: '/department',
    BASIC_INFO: '',
    DESIGNATION_BY_DEPARTMENT: '/designation-by-department/',
  },
  PAYROLL: {
    GET_ALLOWANCE_DEPENDANCY: '/payroll/allowance-dependency',
    GET_ALLOWANCE_TYPE: '/payroll/allowance-type',
    GET_SALARY_HEAD: '/payroll/salary-head',
    GET_ALL_SBU: '/sbu',
    SAVE_SALARY_CONFIG: '/payroll/salary-config',
    GET_SALARY_CONFIG: '/payroll/salary-config',
    GET_CATEGORIZED_SALARY_CONFIG: '/payroll/salary-config/categorized',
    SALARY_PROCESS: {
      PROCESS_SALARY: '/payroll/salary-process',
      UPDATE_SALARY: '/payroll/salary-process/update',
    },
    LOAN: {
      REQUEST: '/payroll/loan',
      GET_ALL_LOANS: '/payroll/loan/get-all-loans',
      GET_BY_SALARY_ID: '/payroll/loan/get-by-salary-id',
    },
    BONUS: {
      PROCESS_BONUS: '/payroll/salary-process/bonus/process-bonus',
    },
  },
  PROJECT: {
    COMMON_URL: '/project',
    ON_GOING: '/project/ongoing',
    ASSIGN_PROJECTS: '/project/assign-project',
    PROJECTS_BY_EMP: '/project/projects-by-emp-id',
    PROJECTS_AND_SUPERVISOR_BY_EMP: '/project/supervisor-and-projects-by-emp-id',
    SUPERVISOR_BY_EMP: '/project/supervisor-by-emp-id',
    FUNCTIONAL_MANAGERS_BY_EMP: '/project/functional-managers-by-emp-id',
    MEMBERS_BY_PROJECT_ID: '/project/members-by-project-id',
    REMOVE: '/project/remove',
    REMOVE_PROJECT: '/project/remove-project',
  },
  NOTICE: {
    COMMON_URL: '/notice',
    ATTACHMENTS: '/notice/attachment',
    GET_NOTICE:'/notice/get_all_notice_current_year',
  },
  USER: {
    COMMON_URL: '/users',
    GET_EMPLOYEE: '/users/employee',
    REGISTER: '/register',
    RESET_PASSWORD: '/auth/reset-pass',
    SAVE_USER_ROLE: '/role-to-user',
  },
  HOLIDAY: {
    COMMON_URL: '/holiday',
    HOLIDAY_NEXT_THREE_MONTH: '/holiday/holidays-by-date',
    MONTHLY_HOLIDAYS: '/holiday/monthly-holidays',
  },
  ORGANOGRAM: '/organogram',
  EVALUATION: {
    ADD: '/evaluation',
    GET_BY_ID: '/evaluation',
    ADD_FILE: '/evaluation/file',
  },
  POLICY: {
    ADD: '/policy',
    GET: '/policy',
    DELETE_ALL: '/policy/delete',
    DELETE_BY_ID: '/policy/delete',
    FIND_BY_CATEGORY: '/policy/find',
    CATEGORY: '/policy/category',
    FILE: '/policy/file',
  },
  REQUEST: {
    EMPLOYEE_DETAILS_INFO: '/employee/detailsInfo',
    EMPLOYEE_BASIC_INFO: '/request/save-employee-information',
    SAVE_NOMINEE_INFORMATION: '/request/save-nominee-information',
    REJECT_NOMINEE_INFORMATION: '/request/reject-employee-information',
    GET_NOMINEE_INFORMATION: '/request/get-nominee-information',
    APPROVE_NOMINEE_INFORMATION: '/request/approve-nominee-information',
    EMPLOYEE_BASIC_INFO_APPROVE: '/request/approve-employee-information',
    GET_EMPLOYEE_BASIC_INFO: '/request/get-employee-information',
    GET_EMPLOYEE_BASIC_INFO_FOR_LIST: '/request/get-employee-information-for-list',
    EXPERIENCE_INFORMATION: '/request/save-experience-information',
    EXPERIENCE_INFORMATION_APPROVE: '/request/approve-experience-information',
    GET_EXPERIENCE_INFORMATION: '/request/get-experience-information',
    EDUCATION_INFORMATION: '/request/save-education-information',
    EDUCATION_INFORMATION_APPROVE: '/request/approve-education-information',
    GET_EDUCATION_INFORMATION: '/request/get-education-information',
    BANK_INFORMATION: '/request/save-bank-information',
    BANK_INFORMATION_APPROVE: '/request/approve-bank-information',
    GET_BANK_INFORMATION: '/request/get-bank-information',
    TRAINING_AND_CERTIFICATE: '/request/save-training-and-certificate',
    TRAINING_AND_CERTIFICATE_APPROVE: '/request/approve-training-and-certificate',
    GET_TRAINING_AND_CERTIFICATE: '/request/get-training-and-certificate',
    ADDRESS_INFORMATION: '/request/save-address-info',
    ADDRESS_INFORMATION_APPROVE: '/request/approve-address-info',
    GET_ADDRESS_INFORMATION: '/request/get-address-info',
    APPROVE: '/request/approve-all-employee-information',
    EMPLOYEE_PROFILE_DETAILS: '/employee/employee-profile-details',
  },
};
