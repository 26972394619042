import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeaveCountService {
  private leaveCountSubject = new Subject<number>();
  leaveCount$ = this.leaveCountSubject.asObservable();

  updateLeaveCount(count: number) {
    this.leaveCountSubject.next(count);
  }
} 