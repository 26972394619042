import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NbAuthService, NbLogoutComponent, NbTokenService} from '@nebular/auth';

@Component({
  selector: 'ngx-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  private pageRefreshed = false;

  constructor(private service: NbAuthService,
              private tokenService: NbTokenService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.tokenService.clear();
    this.service.logout('email');
    const isPageRefreshed = localStorage.getItem('isPageRefreshed');
    this.router.navigate(['/']);
    if (!isPageRefreshed) {
      localStorage.setItem('isPageRefreshed', 'true');
      window.location.reload();
    } else {
      localStorage.removeItem('isPageRefreshed');
      window.sessionStorage.clear();
      localStorage.clear();
    }
  }

}
