import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AttendanceCountService {
  private attendanceCountSubject = new Subject<number>();
  attendanceCount$ = this.attendanceCountSubject.asObservable();

  updateAttendanceCount(count: number) {
    this.attendanceCountSubject.next(count);
  }
} 